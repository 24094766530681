<template>
  <div :class="['content', align]">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="content" v-html="content" />
    <div
      v-if="buttons && buttons.length"
      :class="['button-container', { 'flex-inline': buttons.length > 1 }]"
      :style="{ width: state.buttonsWidth }"
    >
      <CustomButton
        v-for="(button, index) in buttons"
        :key="button.button_text + index"
        :to="getInternalLink(index)"
        :href="getExternalLink(index)"
        class="cta"
        :class="{ 'mgn-l-1': index > 0 }"
        :style-name="button?.type"
        :style-color="button?.style_color"
      >
        {{ button.button_text }}
      </CustomButton>
    </div>
    <div v-if="countdownDate && !state.countDownExpired" class="countdown flex-inline">
      <div v-for="time in state.dateDisplay" :key="time.text" class="date-item flex-inline">
        <div class="flex-col">
          <div v-if="time.text === 'Minutes'" class="number">
            <div ref="minuteTrackerElement" class="minute-holder" v-html="minuteCountdownHtml" />
          </div>
          <span v-else class="number" v-text="time.value" />
          <span class="text" v-text="time.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonCS } from '~/types/contentstack';
import { formatNumDigits } from '~/util/string';
import { getFutureTime } from '~/util/date';
import { extractInternalLink } from '~/util/contentstack/csHelpers';

const props = defineProps({
  countdownDate: {
    type: String,
    default: undefined,
  },
  buttons: {
    type: Array as () => ButtonCS[],
    required: false,
    default: null,
  },
  content: {
    type: String,
    default: undefined,
  },
  align: {
    type: String,
    default: '',
  },
});

const localePath = useLocalePath();

const state = reactive({
  countdownInterval: 0 as any,
  hideCountdownInterval: 0 as any,
  countDownExpired: false,
  hideCountdownExpired: false,
  dateDisplay: [
    {
      text: 'Days',
      value: formatNumDigits(0, 2),
    },
    {
      text: 'Hours',
      value: formatNumDigits(0, 2),
    },
    {
      text: 'Minutes',
      value: 0,
    },
  ],
  minuteSlider: undefined as any,
  hasMounted: false,
  buttonsWidth: '20rem',
});

onMounted(() => {
  if (props?.countdownDate) {
    const countDownDate = new Date(props?.countdownDate).getTime();
    state.countdownInterval = setInterval(() => {
      countdownMovement(countDownDate);
    }, 1000);
    countdownMovement(countDownDate);
  }
  state.hasMounted = true;
});

onUnmounted(() => {
  clearInterval(state.countdownInterval);
  clearInterval(state.hideCountdownInterval);
});

function countdownMovement(countDownDate: number) {
  const { days, hours, minutes, seconds, distance } = getFutureTime(countDownDate);
  // If the count down is finished, hide component
  if (distance < 0) {
    clearInterval(state.countdownInterval);
    state.countDownExpired = true;
  } else {
    state.dateDisplay = [
      {
        text: 'Days',
        value: formatNumDigits(days, 2),
      },
      {
        text: 'Hours',
        value: formatNumDigits(hours, 2),
      },
      {
        text: 'Minutes',
        value: minutes,
      },
    ];
  }
  const minuteChange = seconds === 0;
  setMinuteTracker(minutes, minuteChange);
}
function getInternalLink(i: number) {
  const url = extractInternalLink(props?.buttons?.[i]?.internal_linkConnection?.edges?.[0]) || undefined;
  const localizedUrl = url && localePath ? localePath(url) : url;
  return localizedUrl;
}
function getExternalLink(i: number) {
  return props?.buttons?.[i]?.external_link?.href;
}

const minuteTrackerElement = ref(null);

let minuteCountdownHtml = '';
function setMinuteTracker(curMin: number, newMinute: boolean) {
  minuteCountdownHtml =
    '<div class="upper-minutes' +
    (newMinute ? ' anim-minute' : '') +
    '">' +
    '<div class="number">' +
    minuteFormatter(curMin + 1) +
    '</div>' +
    '<div class="number minute-old' +
    (newMinute ? ' anim-fade' : '') +
    '">' +
    minuteFormatter(curMin) +
    '</div></div>' +
    '<div class="number minute-new' +
    (newMinute ? ' anim-bright' : '') +
    '">' +
    minuteFormatter(curMin - 1) +
    '</div>' +
    '<div class="lower-minutes">' +
    '<div class="number">' +
    minuteFormatter(curMin - 2) +
    '</div>' +
    '<div class="number">' +
    minuteFormatter(curMin - 3) +
    '</div></div>';
}
function minuteFormatter(rawMin: number) {
  let safeMin = rawMin;
  if (rawMin > 59) safeMin -= 60;
  else if (rawMin < 0) safeMin = 60 + safeMin;
  return formatNumDigits(safeMin, 2);
}
</script>

<style lang="scss" scoped>
.countdown {
  width: fit-content;
  margin: 15px 0 0 0;
  padding: 0;
  color: $color-primary-400;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 12px rgba(38, 38, 38, 0.16));
}
.date-item {
  width: 68px;
  height: 40px;
  border: 2px solid $color-neutral-white;
  border-radius: 4px;
  .flex-col {
    align-items: center;
    width: 100%;
    gap: 1px;
  }
  .number {
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 23px;
    border-bottom: 2px solid $color-neutral-white;
  }
  .text {
    font-size: 9px;
    color: $color-neutral-white;
  }
  &:not(:last-child) {
    &::after {
      display: block;
    }
  }
}

.flex-col :deep(.minute-holder) {
  line-height: 0.8em;
  overflow: hidden;
  height: 1.5em;
  text-align: center;
}
.minute-holder :deep(.number) {
  position: relative;
  color: #d6d6d6;
}

.minute-holder :deep(.minute-old),
:deep(.minute-new) {
  color: $color-primary-400;
}

@keyframes fadeNum {
  from {
    color: $color-primary-400;
  }
  35% {
    color: #d6d6d6;
  }
  100% {
    color: #d6d6d6;
  }
}
@keyframes brightNum {
  from {
    color: #d6d6d6;
  }
  35% {
    color: $color-primary-400;
  }
  100% {
    color: $color-primary-400;
  }
}

@keyframes moveNumberOut {
  0% {
    margin-top: -0.4em;
  }
  35% {
    margin-top: -1.2em;
  }
  100% {
    margin-top: -1.2em;
  }
}

.minute-holder :deep(.upper-minutes) {
  margin-top: -0.4em;
  width: 100%;
}
.minute-holder :deep(.anim-minute) {
  animation-name: moveNumberOut;
  animation-duration: 1s;
  animation-timing-function: linear;
}
.minute-holder :deep(.anim-fade) {
  animation-name: fadeNum;
  animation-duration: 1s;
  animation-timing-function: linear;
}
.minute-holder :deep(.anim-bright) {
  animation-name: brightNum;
  animation-duration: 1s;
  animation-timing-function: linear;
}
</style>
